import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Slider from "react-slick"

import ImagePropis from "../images/rusUz-cover.jpg"

import russian1 from "../images/rusUz-1.jpg"
import russian2 from "../images/rusUz-2.jpg"
import OrderRusUz from "../components/orderRusUz"

const imgZhi = [
  {
    id: 1,
    pic: russian1,
  },
  {
    id: 2,
    pic: russian2,
  },
]
const RusPerehod = () => {
  const imageMap = imgZhi.map(image => {
    return (
      <div key={image.id}>
        <div className="flex justify-center">
          <img className="max-h-350" src={image.pic} alt="some" />
        </div>
      </div>
    )
  })

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <Layout>
      <Seo title="Прописи ЖИ-ШИ, ЧА-ЩА, ЧУ-ЩУ" />
      <div className="mb-4">
        <a href="/">
          <button
            type="button"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
          >
            На главную
          </button>
        </a>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2">
          <img src={ImagePropis} alt="cover"/>
        </div>
        <div className="pl-0 md:pl-8 md:w-1/2">
          <h1 className="mb-4 text-2xl font-black tracking-tighter text-black md:text-5xl title-font">
            Прописи русский язык
          </h1>
          <p className="mt-12 mb-0">
            Прописи по русскому языку переход на широкую линейку подходят для
            школьников 2 и 3 класса, кто хочет улучшить свой почерк, кто
            совершенствует навыки письма.
          </p>
          <br />
          <span className="">
            Каждое слово или выражение-это одно упражнение с большой буквы,
            состоящее из 2 узких строчек, одной узкой пунктирной строки перехода
            и широкой линейки. Практика в прописях, старание и усидчивость
            помогут вашему ребенку научится красиво писать в широких строчках.
            Прописывая слова и выражения по несколько раз, ребенок не только
            научится писать в широких строках, но и запомнит правила написания
            слов.
          </span>
          <br />
          <br />
          <span>
            Упражнения в рабочей тетради составлены так, что ребенок прописывает
            каждое слово и в узкой строке и в широкой, переход происходит
            постепенно, без стресса в узкой пунктирной линейке. Есть возможность
            прописывать слова или выражения несколько раз. Пустые строчки для
            самостоятельной работы. Вместе с ребенком выберите самые красивые
            варианты, которые он написал сам в широкой строчке. Тетрадь для
            работы над почерком.
          </span>
          <br />
          <br />
          <span>Учите правило и тренируйте почерк!!!</span>
        </div>
      </div>
      <OrderRusUz />
      <div className="mt-24 mb-24">
        <Slider {...settings}>{imageMap}</Slider>
      </div>
    </Layout>
  )
}

export default RusPerehod
